import { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import CallToAction from '../../../shared/CallToActionButton'
import SvgIcon from '../../../shared/SvgIcon'
import { useAnalytics } from '../../../../common/analytics'
import useOnViewport from 'src/common/hooks/useOnViewport'
import BarCharts from './BarCharts'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { featureToggles } from 'src/common/constants/featureToggles'
import Modal from 'src/components/shared/Modal'
import { cfosDisclousures } from 'src/components/layouts/consts'

const DataVisualization = () => {
  const title = 'Reduce what you owe and free up more cash ¹'
  const { track } = useAnalytics()
  const wasInViewport = useRef(false)
  const [isOnViewport] = useOnViewport('debt-assessment', true)
  const [showModal, setShowModal] = useState(false)

  const handleModalOpen = useCallback(() => {
    setShowModal(true)
    document.body.style.overflow = 'hidden'
  }, [setShowModal])

  const handleModalClose = useCallback(() => {
    setShowModal(false)
    document.body.style.overflow = 'unset'
  }, [setShowModal])
  const { getExperimentVariation } = useFeatureFlags()
  const homeVariation = getExperimentVariation('HomepageRedesign')
  const cfosDisclousuresVariation = getExperimentVariation(
    featureToggles.CFOS_DISCLOUSURES
  )

  const isCfosDisclousuresVariationActive =
    cfosDisclousuresVariation === 'control'
  const dataVisualization =
    homeVariation === 'control-ny'
      ? 'hide_data_visualizatio'
      : 'control_with_bar_chart'

  useEffect(() => {
    if (
      !wasInViewport.current &&
      isOnViewport &&
      dataVisualization !== 'hide_data_visualization'
    ) {
      track(
        {
          additional_info: { section: 'data-visualization' },
          track_event: 'section_view',
          event_type: 'track',
        },
        {},
        'section_view'
      )
      wasInViewport.current = true
    }
  }, [track, dataVisualization, isOnViewport])

  return (
    <>
      <div id="debt-assessment" />
      {dataVisualization === 'hide_data_visualization' ? null : (
        <div
          className="flex flex-col flex-wrap bg-gray-75 p-4 lg:flex-row lg:px-28 lg:py-10"
          id="data-visualization"
        >
          <div className="flex w-full flex-col flex-wrap justify-center gap-x-primary-xs gap-y-6 rounded-lg bg-white px-4 py-8 lg:flex-row lg:gap-x-primary-md lg:gap-y-8 lg:px-10 lg:py-12">
            <div className="w-full text-center xl:mb-0">
              <h2
                className="text-primary-xs font-normal leading-tight tracking-normal text-blue-700 md:leading-10"
                id="data-visualization-title"
              >
                {title}
              </h2>
              <p
                className={`mt-2 text-base font-normal lg:text-lg ${
                  dataVisualization === 'control_with_bar_chart'
                    ? 'text-gray-555'
                    : 'text-black'
                }`}
              >
                {dataVisualization === 'control_with_bar_chart'
                  ? ' Pay down $20,000 est. in debt faster than minimums (including interest)'
                  : 'Pay down $20,000 est. in debt (including interest)'}
              </p>
            </div>
            {dataVisualization === 'control_with_bar_chart' ? (
              <div className="flex w-full flex-col items-center justify-center gap-x-8 lg:justify-between">
                <BarCharts />
              </div>
            ) : null}

            <div className="order-last w-full text-center lg:mt-2">
              <CallToAction
                title={title}
                fontClassName="text-lg font-bold leading-snug text-white !w-full md:w-fit"
                text="Get a free evaluation"
                className="mx-auto mt-2 block md:px-6 lg:mt-0"
                isLeadId={true}
                endIcon={
                  <SvgIcon
                    name="arrowRightCircle"
                    className="ml-2 h-6 w-6 fill-white"
                  />
                }
              />
            </div>
            {isCfosDisclousuresVariationActive ? (
              <div className="order-last w-full text-center">
                <a
                  onClick={handleModalOpen}
                  className="cursor-pointer text-neutral-150 underline underline-offset-4"
                >
                  How are these numbers calculated?
                </a>
                {showModal ? (
                  <Modal
                    bodyClassName="mb-4 mt-9"
                    onClose={handleModalClose}
                    open={showModal}
                    className="w-100 scroll-auto lg:w-[30%]"
                    hasDistanceFromTop={true}
                  >
                    <div className="p-5">
                      <span className="text-[32px] font-bold">
                        How are these numbers calculated?
                      </span>
                      <p className="mt-5 whitespace-pre-line text-left text-14 text-gray-500">
                        {cfosDisclousures}
                      </p>
                      <div className="flex justify-end">
                        <a
                          onClick={handleModalClose}
                          className="group flex w-full items-center justify-center gap-x-3 rounded-lg bg-blue-560 p-3 lg:w-fit"
                        >
                          <span className="whitespace-nowrap text-lg font-bold text-white">
                            Go back
                          </span>
                        </a>
                      </div>
                    </div>
                  </Modal>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

export default DataVisualization
